import { Link } from 'gatsby'
import React from 'react'

import './Error404Page.scss'

const Error404Page = () => {
	return (
		<div className="Error404Page">
			<div>
				<h1>Oups, cette page n'existe pas</h1>
				<p>
					Pas de panique ! Vous pouvez toujours
					<br />
					<Link to="/">Retourner sur l'accueil</Link> ou{' '}
					<Link to="/boutique">découvrir nos produits</Link>
				</p>
			</div>
		</div>
	)
}

export default Error404Page
